<template>
  <div class="home-page">
    <Header />
    <div class="main-container">
      <div class="left-content">
        <div class="tab-list">
          <div class="item" v-for="(item, index) in tabList" :key="index" :class="activeKey == item.routerName ? 'active' : ''" @click="changeTab(item.routerName)">
            <img :src="activeKey == item.routerName ? item.activeIcon : item.icon" alt="" class="icon">
            <span>{{item.text}}</span>
          </div>
        </div>
      </div>
      <div class="main-content">
        <div class="info-tip" v-if="tabObj[activeKey].tip">{{tabObj[activeKey].tip}}</div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

const TAB_LIST = [
  {text: '照片换脸', icon: require('@/assets/img/icon-tab-img.png'), activeIcon: require('@/assets/img/icon-tab-img-active.png'), routerName: 'FaceChangeByImage', tip: '利用AI换脸技术，将被攻击方的人脸换至攻击方的照片中，以达到伪造关键人物身份的目的'},
  {text: '视频换脸', icon: require('@/assets/img/icon-tab-video.png'), activeIcon: require('@/assets/img/icon-tab-video-active.png'), routerName: 'FaceChangeByVideo', tip: '利用AI换脸技术，将被攻击方的人脸换至攻击方的视频中，以达到伪造关键人物身份的目的'},
  {text: '人脸编辑', icon: require('@/assets/img/icon-tab-edit.png'), activeIcon: require('@/assets/img/icon-tab-edit-active.png'), routerName: 'FaceEdit', tip: '基于人脸编辑与生成算法，对人脸属性特征进行编辑，实现改变年龄、改变笑容、改变瞳孔、改变发色等效果'},
  {text: '人脸表情操纵', icon: require('@/assets/img/icon-tab-person.png'), activeIcon: require('@/assets/img/icon-tab-person-active.png'), routerName: 'IdentifyFakeAttack', tip: '通过对抗攻击算法，只用一张照片即可生成点头眨眼等动作视频，可用于攻击身份认证类系统'},
  {text: '人脸安防攻击', icon: require('@/assets/img/icon-tab-makeup.png'), activeIcon: require('@/assets/img/icon-tab-makeup-active.png'), routerName: 'CosmeticAttack', 
  // tip: '作为针对目标检测系统的一种反制手段，让检测系统算法失效，可以达到躲避隐身、冒充身份等目的。人脸躲避：使目标检测系统无法识别目标人脸；人脸冒充：使目标检测系统将目标人脸识别为其他人物'
  },
  {text: '虚拟人脸生成', icon: require('@/assets/img/icon-tab-fake.png'), activeIcon: require('@/assets/img/icon-tab-fake-active.png'), routerName: 'VirtualFace', tip: '运用先进的AI人脸生成技术，随机生成“查无此人”的逼真面孔，可用于制造虚假信息'}
]

const TAB_OBJ = TAB_LIST.reduce((obj, item) => {
  obj[item.routerName] = item;
  return obj;
}, {});

export default {
  name: "home",
  components: {
    Header,
  },
  data() {
    return {
      activeKey: TAB_LIST[0].routerName,
      tabList: TAB_LIST,
      tabObj: TAB_OBJ,
    }
  },

  mounted() {
    this.activeKey = this.$route.name;
  },

  computed: {
    
  },

  methods: {
    changeTab(key) {
      this.activeKey = key;
      this.$router.push({
        name: key
      })
    }
  },
};
</script>

<style lang="less">
  @import "../../assets/less/home";
  @import "../../assets/less/dataGenerate";
</style>
