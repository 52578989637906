<template>
  <div class="custom-header">
    <div class="logo">
      <img src="@/assets/img/logo-new.png" alt="" width="226px" height="21px">
    </div>
    <h3>
      <img src="@/assets/img/main-title.png" alt="">
    </h3>
    <div class="time">
      <span>账号有效期至 {{userInfo.expireDate}}</span>
      <el-dropdown placement="bottom" trigger="click">
        <span class="el-dropdown-link">
          <img src="@/assets/img/icon-user.png" alt="" class="icon-user">
        </span>
        <el-dropdown-menu slot="dropdown" class="custom-dropdown">
          <el-dropdown-item class="user-name">{{userInfo.username}}</el-dropdown-item>
          <el-dropdown-item @click.native="logout" class="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import {dispatch, ACTIONS, state, TYPE, commit} from '@/store';
import { LOGIN } from '@/remote';
import { STORAGE } from '@/utils/const';
import { goLoginPageAndInitData } from '@/utils';

export default {
  name: "custom-header",
  props: {
    
  },
  components: {
  },
  data() {
    return {
      userInfo: {},
    };
  },

  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(STORAGE.RJ_SPECIAL_USER_INFO) || '{}');
  },

  methods: {
    logout(){
      LOGIN.logout().then((res)=>{
        if(res.code == 200){
          this.$message({
            type: 'success',
            message: '退出成功'
          });
          goLoginPageAndInitData();
        }
      }).catch((error)=>{
        console.log(error)
      })
    }
  },
};
</script>

<style lang="less">
  @import "../assets/less/header";
</style>

